$(function () {
    const schoolArray = $('.schoolpicker option').map(function () {
        const img = $(this).data('thumbnail');
        const authType = $(this).data('auth-type');
        const withCode = $(this).data('with-code');
        const slug = $(this).data('slug');

        const text = $(this).text();
        const value = $(this).val();
        return `<li><img src="${img}" alt="" value="${value}" data-auth-type="${authType}" data-slug="${slug}" data-with-code="${withCode}" /><span>${text}</span></li>`;
    }).get();

    const btnSelect = $('.btn-select');
    const schoolListContainer = $('.school-list-container');

    $('#school-list').html(schoolArray.join(''));

    btnSelect.html(schoolArray[0]);
    btnSelect.attr('value', '');

    btnSelect.on('click', function () {
        schoolListContainer.toggle();

        if (schoolListContainer.is(':visible')) {
            btnSelect.addClass('open');
        } else {
            btnSelect.removeClass('open');
        }
    });

    $(document).on('click', function (event) {
        if (!$(event.target).closest('.btn-select').length && schoolListContainer.is(':visible')) {
            schoolListContainer.hide();
            btnSelect.removeClass('open');
        }
    });
});